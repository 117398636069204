import gql from 'graphql-tag'
import clientFragment from './ClientFrangment'

export const CREATE_CLIENT_MUTATION = gql`
  mutation CreateClient($clientInput: ClientInput) {
    createClient(clientInput: $clientInput) {
      ...${clientFragment}
    }
  }
`

export const DELETE_CLIENT_MUTATION = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(id: $id) {
      id
    }
  }
`

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UpdateClient($id: ID!, $clientInput: ClientInput) {
    updateClient(id: $id, clientInput: $clientInput) {
      ...${clientFragment}
    }
  }
`