<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="px-4">
          <!-- <pre>
            {{provinces($i18n.locale)}}
          </pre> -->
          <v-col cols="12" class="justify-center text-center py-4">
            <div style="background: rgba(255, 255, 255, 0.7)" class="py-3">
              <v-list-item>
                <v-hover v-slot:default="{}">
                  <v-list-item>
                    <v-list-item-avatar size="150" class="mx-auto" color="#fff">
                      <croppa
                        class="croppa"
                        v-model="croppa"
                        :show-remove-button="false"
                        disable-click-to-choose
                        :width="150"
                        :height="150"
                        :placeholder="$t('form_candidate_upload_placeholder')"
                        :placeholder-font-size="14"
                        :initial-image="
                          candidate.photo
                            ? `${apiUrl}/images/candidate/${candidate.photo}`
                            : `/avatar.png`
                        "
                      >
                      </croppa>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-hover>
              </v-list-item>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="primary"
                    style="margin-top: -15%; margin-left: 20%"
                    icon
                    small
                    @click="croppa.chooseFile()"
                    ><v-icon>mdi-camera-plus</v-icon></v-btn
                  >
                </template>
                <div v-html="$t('form_candidate_upload')"></div>
              </v-tooltip>
            </div>
          </v-col>
          <v-text-field
            v-model="candidate.name"
            :label="$t('form_candidate_name')"
            outlined
            name="name"
            type="text"
            dense
            required
            :rules="requiredRules"
          ></v-text-field>
          <v-text-field
            v-model="candidate.lastName"
            :label="$t('form_candidate_surname')"
            outlined
            name="name"
            type="text"
            dense
            required
            :rules="requiredRules"
          ></v-text-field>
          <v-text-field
            v-model="candidate.profession"
            :label="$t('form_candidate_job')"
            outlined
            name="name"
            type="text"
            dense
            required
            :rules="requiredRules"
          ></v-text-field>
          <v-select
            v-model="candidate.genre"
            :label="$t('form_candidate_gender')"
            :items="getgender($i18n.locale)"
            item-text="text"
            item-value="key"
            outlined
            dense
            :rules="requiredRules"
          ></v-select>
          <v-menu
            ref="birthMenu"
            v-model="birthMenu"
            :close-on-content-click="false"
            :return-value.sync="candidate.dateBirth"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="candidate.dateBirth"
                value="02-04-1994"
                :label="$t('form_candidate_birth')"
                prepend-inner-icon="event"
                placeholder="YYYY-MM-DD"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker v-model="candidate.dateBirth" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="birthMenu = false">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.birthMenu.save(candidate.dateBirth)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
              editable
              color="primary"
              dense
            >
              {{ $t("form_candidate_profile") }} 1
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
              editable
              color="primary"
            >
              Curriculum 2
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items flat>
            <v-stepper-content step="1">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">{{
                    $t("form_candidate_profile")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="candidate.presentation"
                    outlined
                    name="input"
                    :label="$t('form_candidate_experience')"
                    :counter="300"
                    :maxlength="300"
                    rows="4"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="candidate.availability"
                    :items="availabilityOptions"
                    item-text="text"
                    item-value="key"
                    :label="$t('form_candidate_Availability')"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-currency-field
                    :label="$t('form_candidate_expct1')"
                    outlined
                    dense
                    v-model="candidate.basicSalary"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-currency-field
                    :label="$t('form_candidate_expct3')"
                    outlined
                    dense
                    v-model="candidate.idealSalary"
                    hide-details
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <vue-tel-input-vuetify
                    v-model="candidate.telephone"
                    :label="$t('form_user_phone')"
                    persistent-hint
                    outlined
                    dense
                    required
                    maxLen="15"
                    @input="onInputPhone"
                  >
                  </vue-tel-input-vuetify>
                </v-col>
                <v-col cols="12" md="6" class="separete">
                  <vue-tel-input-vuetify
                    v-model="candidate.telephone2"
                    :label="`${$t('Whatsapp')}`"
                    persistent-hint
                    outlined
                    dense
                    maxLen="15"
                    @input="onInputPhone2"
                  >
                  </vue-tel-input-vuetify>
                  <!-- <v-text-field
                    v-model="candidate.telephone"
                    :label="$t('form_user_phone')"
                    outlined
                    hide-details
                    dense
                    :rules="requiredRules"
                  ></v-text-field> -->
                </v-col>
                <v-row class="mx-0 separete">
                  <v-col cols="12" md="12" class="onTop">
                    <v-text-field
                      v-model="candidate.email"
                      label="E-mail"
                      outlined
                      :rules="requiredRules"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-0 separete">
                  <v-col cols="12" md="6" class="onTop">
                    <!-- <pre>{{$i18n.locale}}</pre> -->
                    <v-autocomplete
                      v-model="candidate.nationality"
                      :items="nationalities($i18n.locale)"
                      item-text="text"
                      item-value="key"
                      :label="$t('form_candidate_nacion')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="onTop">
                    <v-autocomplete
                      v-model="candidate.province"
                      :items="provinces($i18n.locale)"
                      item-text="text"
                      item-value="key"
                      :label="$t('province')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mx-0 separete">
                  <v-col cols="12" md="12" class="separete onTop">
                    <v-text-field
                      v-model="candidate.address"
                      :label="$t('form_client_adress')"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <template v-for="(lang, index) in candidate.languages">
                  <v-col cols="12" md="12" :key="`${index}lang`">
                    <v-row class="separete">
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="lang.name"
                          :items="languages"
                          item-text="text"
                          item-value="key"
                          :label="$t('languages')"
                          dense
                          outlined
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="5" class="separete">
                        <v-autocomplete
                          v-model="lang.level"
                          :items="levelLanguages"
                          item-text="text"
                          item-value="key"
                          :label="$t('level')"
                          dense
                          outlined
                          required
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="1" class="text-center mt-1 separete">
                        <v-btn
                          x-small
                          outlined
                          fab
                          color="secondary"
                          @click="
                            index === 0 ? addLanguage() : removeLanguage(index)
                          "
                          class="separete"
                        >
                          <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                          <v-icon v-else small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <v-col cols="12" md="12">
                  <p>{{ $t("form_candidate_tools") }}</p>
                  <v-divider></v-divider>
                  <p class="pt-2 citation font-italic">
                    {{ $t("form_candidate_work_tools") }}
                  </p>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-chip-group column multiple>
                    <v-chip
                      v-for="(skill, index) in candidate.otherSkills"
                      label
                      small
                      close
                      :key="`${index}33`"
                      @click:close="removeSkill(index)"
                      >{{ skill }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="9" md="6">
                  <v-text-field
                    v-model="skill"
                    :label="$t('form_candidate_work_skill')"
                    outlined
                    dense
                    :counter="30"
                    :maxlength="30"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="6" class="px-0">
                  <v-btn outlined icon @click="addSkill()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="12">
                  <p>{{ $t("form_candidate_inter") }}</p>
                  <v-divider></v-divider>
                  <p class="pt-2 citation font-italic">
                    {{ $t("form_candidate_area_inter") }}
                  </p>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-chip-group column multiple>
                    <v-chip
                      v-for="(area, index) in candidate.areasInterest"
                      label
                      small
                      close
                      :key="`${index}11`"
                      @click:close="removeAreaInterest(index)"
                      >{{ $t(area) }}</v-chip
                    >
                  </v-chip-group>
                </v-col>
                <v-col cols="9" md="6">
                  <v-autocomplete
                    v-model="areaInterest"
                    :items="trainingAreas($i18n.locale)"
                    item-text="text"
                    item-value="key"
                    :label="$t('form_candidate_area')"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="3" md="6" class="px-0">
                  <v-btn outlined icon @click="addAreaInterest()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="12">
                  <p>{{ $t("form_candidate_networks") }}</p>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="candidate.media.facebook"
                    label="Facebook"
                    outlined
                    name="facebook"
                    prepend-inner-icon="mdi-facebook"
                    type="text"
                    dense
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="candidate.media.linkedin"
                    label="Linkedin"
                    outlined
                    name="linkedin"
                    prepend-inner-icon="mdi-linkedin"
                    type="text"
                    dense
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="candidate.media.twitter"
                    label="Twitter"
                    outlined
                    name="twitter"
                    prepend-inner-icon="mdi-twitter"
                    type="text"
                    dense
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-row justify-end mt-10 mb-2 px-5">
                <v-btn text @click="back" color="primary">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" @click="e1 = 2">
                  {{ $t("next") }}
                </v-btn>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="subtitle">
                {{ $t("form_candidate_experience_label") }}
              </div>
              <template
                v-for="(expe, index) in candidate.professionalExperience"
              >
                <v-row :key="`${index}22`">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="expe.entity"
                      :label="$t('institution')"
                      outlined
                      dense
                      required
                      hide-details
                      @input="getEntity(expe.entity)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <!-- {{ institution }} -->
                    <v-autocomplete
                      v-model="expe.sector"
                      :items="sector"
                      item-text="text"
                      item-value="key"
                      :label="$t('select_sector')"
                      dense
                      outlined
                      :rules="requiredRulesSector"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menuIni"
                      v-model="menuIni[index]"
                      :close-on-content-click="false"
                      :return-value.sync="expe.startDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="expe.startDate"
                          :label="$t('form_candidate_date_int')"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="expe.startDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuIni[index] = false"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuIni[index].save(expe.startDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menuEnd"
                      v-model="menuEnd[index]"
                      :close-on-content-click="false"
                      :return-value.sync="expe.endDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="expe.endDate"
                          :label="$t('form_candidate_date_end')"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-date-picker
                          v-model="expe.endDate"
                          no-title
                          scrollable
                          :min="expe.startDate"
                          :disabled="expe.still_working"
                        >
                        </v-date-picker>
                        <v-row style="margin-top: -40px; margin-bottom: 0%">
                          <v-col>
                            <v-alert
                              color="primary_text"
                              dense
                              class="text-left black--text caption mb-0"
                            >
                              <v-row>
                                <v-col cols="2" md="2">
                                  <v-icon
                                    v-on="on"
                                    class="ml-2 mr-5 mt-3"
                                    color="primary"
                                  >
                                    mdi-information-variant-circle-outline
                                  </v-icon></v-col
                                >
                                <v-col cols="10" md="10"
                                  ><span style="font-size: 8pt">{{
                                    $t("select_the_field_below")
                                  }}</span></v-col
                                >
                              </v-row>
                            </v-alert></v-col
                          >
                        </v-row>
                        <v-row class="mx-0 " style="margin-top: -40px;">
                          <v-col cols="12" md="12">
                            <v-checkbox
                              v-model="expe.still_working"
                              color="primary"
                              hide-details
                            >
                              <template v-slot:label>
                                <div class="black--text caption">
                                  {{ $t("still_working") }}
                                </div>
                              </template>
                            </v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-0 mx-0">
                          <v-col cols="12" md="12">
                            <v-row class="mr-0">
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menuEnd[index] = false"
                                >{{ $t("cancel") }}</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menuEnd[index].save(expe.endDate)"
                                >OK</v-btn
                              >
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="expe.office"
                      :label="$t('form_user_Office')"
                      outlined
                      dense
                      required
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="expe.description"
                      outlined
                      :label="$t('form_candidate_activities')"
                      :counter="440"
                      :maxlength="440"
                      rows="4"
                      hide-details
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-btn
                      v-if="index === 0"
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="addExperience()"
                    >
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      icon
                      fab
                      color="secondary"
                      @click="removeExperience(index)"
                      class="pb-2"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <div class="subtitle mt-8">
                {{ $t("form_candidate_academic_level") }}
              </div>
              <v-divider class="pb-2"></v-divider>
              <template v-for="(formation, index) in candidate.formation">
                <v-row :key="`${index}formation`">
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="formation.level"
                      :items="academicLevel"
                      item-text="text"
                      item-value="key"
                      outlined
                      :label="$t('level')"
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="separete">
                    <v-autocomplete
                      v-model="formation.course"
                      :items="trainingCourses($i18n.locale)"
                      item-text="text"
                      item-value="key"
                      outlined
                      :label="$t('form_candidate_curs')"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" class="separete">
                    <v-text-field
                      v-model="formation.institution"
                      :label="$t('institution')"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="separete">
                    <v-menu
                      ref="menu"
                      v-model="menu[index]"
                      :close-on-content-click="false"
                      :return-value.sync="formation.startDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formation.startDate"
                          value="02-04-1994"
                          :label="$t('form_candidate_date_int')"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formation.startDate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu[index] = false"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu[index].save(formation.startDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" class="separete">
                    <v-menu
                      ref="menu2"
                      v-model="menu2[index]"
                      :close-on-content-click="false"
                      :return-value.sync="formation.endDate"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="formation.endDate"
                          value="02-04-1994"
                          :label="$t('form_candidate_date_end')"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formation.endDate"
                        no-title
                        scrollable
                        :min="formation.startDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu2[index] = false"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2[index].save(formation.endDate)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-btn
                      v-if="index === 0"
                      x-small
                      outlined
                      fab
                      color="secondary"
                      @click="addFormation()"
                    >
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      small
                      icon
                      fab
                      color="secondary"
                      @click="removeFormation(index)"
                      class="pb-2"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <div class="subtitle mt-8">{{ $t("upload_documents") }}</div>
              <v-divider class="mb-2"></v-divider>
              <!-- <pre>{{candidate.certificates}}</pre> -->
              <v-row class="d-flex flex-row">
                <div class="pt-3 px-1">
                  <v-btn
                    class="ma-2"
                    x-small
                    outlined
                    fab
                    color="secondary"
                    @click="dialogCertificate = true"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                  <span v-for="(cert, index) in documents" :key="index">
                    <v-chip label outlined>
                      <span>{{ cert.certificate.upload.filename }}</span>
                    </v-chip>
                    <v-btn
                      x-small
                      icon
                      fab
                      color="secondary"
                      @click="openConfirmeDialog(index)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>
                  <!-- <pre>
                    {{documents}}
                  </pre> -->
                </div>
              </v-row>
              <div class="subtitle mt-8">{{ $t("form_candidate_ref") }}</div>
              <v-divider class="mb-2"></v-divider>
              <v-row
                v-for="(reference, index) in candidate.references"
                :key="index"
              >
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="reference.fullName"
                    :label="$t('form_user_name')"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="reference.office"
                    :label="$t('form_user_Office')"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="reference.telephone"
                    :label="$t('form_user_phone')"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="pt-0">
                  <v-btn
                    v-if="index === 0"
                    x-small
                    outlined
                    fab
                    color="secondary"
                    @click="addReference()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    small
                    icon
                    fab
                    color="secondary"
                    @click="removeReference(index)"
                    class="pb-2"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="d-flex flex-row justify-end mt-8 mb-2 px-6">
                <v-btn text @click="back" color="primary">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn type="submit" color="primary" :disabled="!valid" tile>{{
                  $t("save")
                }}</v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <file-success-dialog
      :dialog="fileShowSuccess"
      :message="success"
      @close="fileShowSuccess = false"
    />
    <v-dialog v-model="dialogCertificate" max-width="800">
      <!-- eslint-disable-next-line vue/valid-v-on -->
      <v-card justify="center" class="pb-10">
        <!-- <v-row justify="center"> -->
        <!-- </v-row> -->
        <vue-dropzone
          id="dropzone"
          :options="dropzoneOptions"
          ref="dropzone"
          @vdropzone-complete="startImport"
          @vdropzone-drop="startLoading"
          @vdropzone-file-added="startLoading"
        ></vue-dropzone>
        <!-- <v-checkbox class="pl-12" v-model="checkbox1" label="Enviar email para os candidatos completarem o cadastro."></v-checkbox> -->
      </v-card>
    </v-dialog>
    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('remove_doc_quest')"
      @yes="removeCertificate()"
      @no="confirm = false"
    />
  </v-form>
</template>

<script>
import { API_URL } from "@/api";
import { CREATE_CANDIDATE_ENTITY_MUTATION } from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import Candidate from "@/models/Candidate";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import fileSuccessDialog from "./../../../components/SuccessDialog.vue";
import dialogMixins from "@/mixins/dialog";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import vueDropzone from "vue2-dropzone";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
export default {
  name: "ProfileEdit",
  components: {
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    VueTelInputVuetify,
    vueDropzone,
    fileSuccessDialog,
    ConfirmDialog,
  },
  mixins: [dialogMixins],
  data: () => ({
    apiUrl: API_URL,
    areaInterest: "",
    birthMenu: false,
    candidate: new Candidate(),
    institution: "",
    croppa: {},
    e1: 1,
    menu: [false],
    menu2: [false],
    menuIni: [false],
    menuEnd: [false],
    skill: "",
    valid: true,
    phone: {
      number: "",
      valid: false,
      country: undefined,
    },
    phone2: {
      number: "",
      valid: false,
      country: undefined,
    },
    fileShowSuccess: false,
    dialogCertificate: false,
    documents: [],
  }),
  created() {
    this.setInit();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      nationalities: "library/nationalities",
      getPublishingLang: "library/publishingLang",
      trainingAreas: "library/trainingAreas",
      getgender: "library/gender",
      trainingCourses: "library/trainingCourses",
      provinces: "library/provinces",
    }),
    sector() {
      return [
        { key: "private_sec", text: this.$t("private_sec") },
        { key: "NGO", text: this.$t("NGO") },
        { key: "public_sec", text: this.$t("public_sec") },
      ];
    },
    dropzoneOptions() {
      return {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
        maxFilesize: 2.0,
        dictDefaultMessage:
          '<div class="v-card v-sheet theme--light grey lighten-3" style="height: 250px;">' +
          '<div class="text-center">' +
          this.$t("file_doc_upload") +
          '<div style="margin-top:-5%"><i aria-hidden="true" class="v-icon notranslate mb-5 v-icon--right mdi mdi-cloud-upload theme--light primary--text"></i></div>' +
          '<button type="button" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default">' +
          '<span class="v-btn__content">' +
          this.$t("import_file") +
          '</span></button></div><p class="text-center pt-2 overline">.pdf .doc .docx .rtf</p></div>',
      };
    },
    genres() {
      return [this.$t("male"), this.$t("female")];
    },
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
    requiredRulesSector() {
      if (this.institution) {
        return [(v) => !!v || this.$t("form_user_valid")];
      }
      return 0;
    },
    requiredRulesItem() {
      return [
        (v) => {
          if (!v) {
            return this.$t("form_user_valid");
          } else if (!isNaN(v)) {
            return this.$t("select_province");
          }
        },
      ];
    },
    availabilityOptions() {
      return [
        { key: "fulltime", text: this.$t("fulltime") },
        { key: "part_time", text: this.$t("part_time") },
        { key: "freelancer", text: this.$t("freelancer") },
      ];
    },
    academicLevel() {
      return [
        { key: "basic", text: this.$t("basic") },
        { key: "elementary", text: this.$t("elementary") },
        { key: "dedium", text: this.$t("dedium") },
        { key: "bachelor", text: this.$t("bachelor") },
        { key: "graduation", text: this.$t("graduation") },
        { key: "master_degree", text: this.$t("master_degree") },
        { key: "doctorate", text: this.$t("doctorate") },
        { key: "Phd", text: this.$t("Phd") },
      ];
    },
    languages() {
      return [
        { key: "portuguese", text: this.$t("portuguese") },
        { key: "english", text: this.$t("english") },
        { key: "french", text: this.$t("french") },
        { key: "german", text: this.$t("german") },
        { key: "spanish", text: this.$t("spanish") },
        { key: "barwe", text: "Barwe" },
        { key: "chewa", text: "Chewa" },
        { key: "chiyao", text: "Chiyao" },
        { key: "chopi", text: "Chopi" },
        { key: "chuwabu", text: "Chuwabu" },
        { key: "dema", text: "Dema" },
        { key: "kokola", text: "Kokola" },
        { key: "koti", text: "Koti" },
        { key: "kunda", text: "Kunda" },
        { key: "lolo", text: "Lolo" },
        { key: "lomwe", text: "Lomwe" },
        { key: "maindo", text: "Maindo" },
        { key: "makhuwa", text: "Makhuwa" },
        { key: "makonde", text: "Makonde" },
        { key: "makwe", text: "Makwe" },
        { key: "manyawa", text: "Manyawa" },
        { key: "marenje", text: "Marenje" },
        { key: "mwani", text: "Mwani" },
        { key: "nathembo", text: "Nathembo" },
        { key: "ndau", text: "Ndau" },
        { key: "ngoni", text: "Ngoni" },
        { key: "nsenga", text: "Nsenga" },
        { key: "nyungwe", text: "Nyungwe" },
        { key: "phimbi", text: "Phimbi" },
        { key: "ronga", text: "Ronga" },
        { key: "sena", text: "Sena" },
        { key: "shona", text: "Shona" },
        { key: "swahili", text: "Swahili" },
        { key: "swati", text: "Swati" },
        { key: "takwane", text: "Takwane" },
        { key: "tawara", text: "Tawara" },
        { key: "tewe", text: "Tewe" },
        { key: "tonga", text: "Tonga" },
        { key: "tsonga", text: "Tsonga" },
        { key: "tswa", text: "Tswa" },
        { key: "zulu", text: "Zulu" },
      ];
    },
    levelLanguages() {
      return [
        { key: "basic", text: this.$t("basic") },
        { key: "intermediate", text: this.$t("intermediate") },
        { key: "advanced", text: this.$t("advanced") },
      ];
    },
  },
  methods: {
    ...mapActions({
      setCandidateState: "candidate/setCandidate",
    }),
    onInputPhone(formattedNumber, { number, valid, country }) {
      this.phone.number = number.international;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
    },

    onInputPhone2(formattedNumber, { number, valid, country }) {
      this.phone2.number = number.international;
      this.phone2.valid = valid;
      this.phone2.country = country && country.name;
    },
    addAreaInterest() {
      this.candidate.areasInterest.push(this.areaInterest);
      this.areaInterest = "";
    },
    addExperience() {
      this.candidate.professionalExperience.push({
        office: "",
        startDate: null,
        endDate: null,
        entity: "",
        description: "",
      });
      this.menuIni.push(false);
      this.menuEnd.push(false);
    },
    addFormation() {
      this.candidate.formation.push({
        course: "",
        level: "",
        status: "",
        institution: "",
      });
    },
    addLanguage() {
      this.candidate.languages.push({ name: "", level: "" });
    },
    addReference() {
      this.candidate.references.push({
        fullName: "",
        office: "",
        telephone: "",
      });
    },
    addSkill() {
      this.candidate.otherSkills.push(this.skill);
      this.skill = "";
    },
    removeAreaInterest(index) {
      this.candidate.areasInterest.splice(index, 1);
    },
    removeExperience(index) {
      this.candidate.professionalExperience.splice(index, 1);
    },
    removeFormation(index) {
      this.candidate.formation.splice(index, 1);
    },
    removeLanguage(index) {
      this.candidate.languages.splice(index, 1);
    },
    removeReference(index) {
      this.candidate.references.splice(index, 1);
    },
    removeSkill(index) {
      this.candidate.otherSkills.splice(index, 1);
    },
    setInit() {
      this.candidate.belongsTo = this.currentUser.entity.id;
      this.candidate.telephone = "+258";
      this.candidate.telephone2 = "+258";
      this.candidate.areasInterest = [];
      this.candidate.languages = [{ name: "", level: "" }];
      this.candidate.otherSkills = [];
      this.candidate.media = { facebook: "", linkedin: "", twitter: "" };
      this.candidate.professionalExperience = [
        {
          office: "",
          startDate: null,
          endDate: null,
          entity: "",
          description: "",
        },
      ];
      this.candidate.formation = [
        {
          course: "",
          level: "",
          status: "",
          institution: "",
          startDate: "",
          endDate: "",
        },
      ];
      this.candidate.references = [
        {
          fullName: "",
          office: "",
          telephone: "",
        },
      ];
      this.candidate.certificates = [];
    },
    async validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        await this.submit();
      }
    },
    back() {
      this.$emit("cancel");
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async removeCertificate() {
      try {
        this.confirm = false;
        this.isLoading = true;
        this.candidate.certificates.splice(this.removeId, 1);
        this.documents.splice(this.removeId, 1);
        this.success = this.$t("remove_doc_success");
        this.fileShowSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    getEntity(institution) {
      this.institution = institution;
    },
    async startImport() {
      try {
        if (this.isLoading) {
          const certificate = await this.$refs.dropzone.getAcceptedFiles();
          certificate.forEach(async (certificate) => {
            this.documents.push({ certificate: certificate });
            this.success = this.$t("file_doc_upload_sucess");
            this.fileShowSuccess = true;
            this.dialogCertificate = false;
          });
        }
      } catch (error) {
        this.error = this.$t("size_worning");
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    startLoading() {
      this.isLoading = true;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          this.candidate.newPhoto = this.croppa.generateDataUrl(
            "image/jpeg",
            0.8
          );
          this.candidate.certificates = [];

          const { data } = await this.$apollo.mutate({
            mutation: CREATE_CANDIDATE_ENTITY_MUTATION,
            variables: {
              candidateInput: {
                ...this.candidate,
                documents: this.documents ? this.documents : [],
                telephone: this.candidate.telephone
                  ? this.candidate.telephone.replace("+", "").replace(/\s/g, "")
                  : null,
                telephone2: this.candidate.telephone2
                  ? this.candidate.telephone2
                      .replace("+", "")
                      .replace(/\s/g, "")
                  : null,
              },
              fromCandidate: true,
            },
          });
          this.setCandidateState(data.createCandidateEntity);
          this.success = this.$t("form_candidate_success");
          this.showSuccess = true;
          this.candidate = new Candidate();
          this.setInit();
          this.e1 = 1;
        } catch (error) {
          this.error = this.$t(formatError(error.message));
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.show-btns:not(.on-hover) {
  color: #daf4fd !important;
}
</style>
