<template>
  <div class="fill-height" fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <!-- <v-row> -->
            <!-- <skeleton-loading v-if="$apollo.queries.loading" /> -->
            <!-- <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
              <v-skeleton-loader
                v-if="$apollo.queries.statistics.loading"
                type="article, actions"
              ></v-skeleton-loader>
              <activity-card v-else  :item="item" />
            </v-col>
          </v-row> -->
          <v-row v-if="$apollo.queries.statistics.loading">
            <v-col cols="12" md="3" v-for="index in items.length" :key="index">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
              <activity-card :item="item" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" class="mt-2">
        <p class="text-center title black--text text-uppercase">
         {{$t("welcome")}}
        </p>
        <p class="text-center black--text">
          {{$t("welcome_init")}}
        </p>
      </v-col>

      <v-col cols="12" md="12" class="mt-2">
        <v-row>
          <v-col cols="12" md="4" v-for="action in actions" :key="action.title">
            <register-card :data="action" @openDialog="openDialog" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <add-client-dialog
      :client="client"
      :dialog="dialogClient"
      @close="dialogClient = false"
    />
    <add-candidate-dialog
      :dialog="dialogCandidate"
      @close="dialogCandidate = false"
    />
    <add-user-dialog
      :dialog="dialogUser"
      :user="user"
      @close="dialogUser = false"
    />
  </div>
</template>

<script>
import { STATISTICS_QUERY } from './../graphql/Query'
import { VIEW_VACANCY_SUBSCRIPTION } from './../graphql/Subscription'
import { mapGetters } from 'vuex'
import ActivityCard from "../components/ActivityCard.vue";
import RegisterCard from "../components/RegisterCard.vue";
import AddUserDialog from "./../../setting/components/AddUserDialog.vue";
import AddClientDialog from "./../../client/components/AddClientDialog.vue";
import Client from "@/models/Client";
import User from "@/models/User";
import AddCandidateDialog from "../../candidate/components/AddCandidateDialog.vue";
export default {
  name: "Home",
  components: {
    ActivityCard,
    AddClientDialog,
    AddUserDialog,
    RegisterCard,
    AddCandidateDialog,
  },
  data: () => ({
    actions: [],
    totalCandidates: 0,
    client: new Client(),
    dialogCandidate: false,
    dialogClient: false,
    dialogUser: false,
    statistics: {},
    user: new User(),
  }),
  apollo: {
    statistics: {
      query: STATISTICS_QUERY,
      fetchPolicy: 'no-cache'
    },
    $subscribe: {
      viewVacancy: {
        query: VIEW_VACANCY_SUBSCRIPTION,
        result ({ data }) {
          if (this.currentUser.entity.id == data.viewVacancy) {
            this.statistics.totalViewsVacancy += 1;
          }
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    items () {
      return [
        {
          color: "light-blue",
          icon: "mdi-chart-line",
          iconColor: "#A0C9FF",
          title: "activities",
          // value: "activities_objective",
          value: 0
        },
        {
          color: "red",
          icon: "mdi-account-group",
          iconColor: "#F8AAAD",
          title: "activite_candidade",
          value: "+" + this.statistics.totalCandidates,
        },
        {
          color: "light-green",
          icon: "mdi-eye",
          iconColor: "#8CF1B7",
          title: "activite_vacancy",
          value: "+" + this.statistics.totalViewsVacancy,
        },
        {
          color: "secondary",
          icon: "mdi-cash",
          iconColor: "#ACD2E0",
          title: "activite_Total",
          value: "+" + 0.0,
        },
      ]
    }
  },
  async created() {
    (this.actions = [
      { title: "user", titleButton: "create_user" },
      { title: "clients", titleButton: "create_customer" },
      { title: "candidate", titleButton: "create_candidate" },
    ]);
  },
  methods: {
    openDialog(title) {
      switch (title) {
        case "user":
          this.dialogUser = true;
          break;
        case "clients":
          this.dialogClient = true;
          break;
        case "candidate":
          this.dialogCandidate = true;
          break;
      }
    },
  },
 
};
</script>
